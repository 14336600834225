<template>
  <div class="costing-selection">
    <form-group
      :validator="v$.value[jobLabelAttribute]"
      v-if="jobLabelEnabled"
      label="Job label"
      class="job-label"
      v-bind="formGroupConfig"
    >
      <template #default="slotProps">
        <label-select
          v-bind="slotProps"
          v-model="value[jobLabelAttribute]"
          :multiple="false"
          labelType="work"
          :disabled="disabled"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[jobCategoryAttribute]"
      v-if="jobCategoryEnabled && sortedJobCategories.length > 0"
      label="Job category"
      class="job-category"
      v-bind="formGroupConfig"
    >
      <template #default="slotProps">
        <key-multiselect
          v-bind="slotProps"
          v-model="value[jobCategoryAttribute]"
          :options="sortedJobCategories"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :disabled="disabled"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[jobAttribute]"
      label="Job"
      class="job"
      v-bind="formGroupConfig"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="job"
          v-model="value[jobAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :serviceFetch="fetchJobs"
          :disabled="disabled"
          @fullValueChange="job = $event"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[jobPhaseAttribute]"
      label="Job phase"
      class="job-phase"
      v-bind="formGroupConfig"
      v-if="jobPhaseEnabled"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="jobPhase"
          v-model="value[jobPhaseAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :serviceFetch="fetchJobPhases"
          :disabled="disabled"
          @fullValueChange="jobPhase = $event"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[costCodeAttribute]"
      label="Cost code"
      class="cost-code"
      v-bind="formGroupConfig"
      v-if="costCodeEnabled"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="costCode"
          v-model="value[costCodeAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :disabled="disabled"
          :serviceFetch="fetchCostCodes"
          @fullValueChange="costCode = $event"
        />
      </template>
    </form-group>
    <form-group v-if="enablePartialCosting">
      <b-form-checkbox v-model="value[costingIsPartialAttribute]" :disabled="disabled">
        Costing Selection is Partial
        <help-text-icon>
          If checked, then the worker will be prompted on the time clock to select
          any fields which were not locked here, as long as that field has values to select.
          <template v-if="showMinVersion">
            <br><br>
            This feature requires time clock mobile app version 2.11 or later for job phase and cost code partial lock.
            The job label lock requires version 3.1 or later.
          </template>
        </help-text-icon>
      </b-form-checkbox>
    </form-group>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import JobService from '../costing/services/JobService'
import JobPhaseService from '../costing/services/JobPhaseService'
import CostCodeService from '../costing/services/CostCodeService'
import KeyMultiselect from '@/components/KeyMultiselect.vue'
import LabelSelect from '@/views/settings/organization/LabelSelect.vue'
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import ChildValidation from '@/mixins/ChildValidation'
import _ from 'lodash'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import { forceArray } from '@/utils/misc'
import { useVuelidate } from '@vuelidate/core'
import { helpers } from '@vuelidate/validators'

export default {
  setup () {
    return {
      v$: useVuelidate({ $scope: false, $stopPropagation: true })
    }
  },
  name: 'CostingSelection',
  components: {
    HelpTextIcon,
    KeyMultiselect,
    LabelSelect,
    RemoteMultiselect
  },
  mixins: [ChildValidation],
  props: {
    value: Object,
    // For bulk actions, we are querying for common values.
    worker: [Number, Array],
    deviceOrgUnit: [Number, Array],
    orgUnit: [Number, Array],
    department: [Number, Array],
    userLabels: Array,
    customFieldValues: Array,
    jobLabelAttribute: {
      type: String,
      default: 'jobLabel'
    },
    jobCategoryAttribute: {
      type: String,
      default: 'jobCategory'
    },
    jobAttribute: {
      type: String,
      default: 'job'
    },
    jobPhaseAttribute: {
      type: String,
      default: 'jobPhase'
    },
    costCodeAttribute: {
      type: String,
      default: 'costCode'
    },
    costingIsPartialAttribute: {
      type: String,
      default: 'costingIsPartial'
    },
    enablePartialCosting: {
      type: Boolean,
      default: true
    },
    enableJobCategory: {
      type: Boolean,
      default: true
    },
    enableJobRequiredValidation: {
      type: Boolean,
      default: false
    },
    formGroupConfig: Object,
    invalidMessage: {
      type: String,
      default: 'This selection is not valid.'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    showMinVersion: {
      type: Boolean,
      default: true
    },
    disableRequirePerms: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // These values each hold entire option object,
      // for filtering and validation.
      job: null,
      jobPhase: null,
      costCode: null
    }
  },
  computed: {
    ...mapState(['jobRequired', 'organizationId']),
    ...mapGetters(['enhancedCostingEnabled']),
    ...mapGetters({
      jobCategories: 'jobCategories/sortedItems'
    }),
    jobCategoryEnabled () {
      return this.enableJobCategory && this.$store.getters['jobCategoryEnabled']
    },
    sortedJobCategories () {
      return this.jobCategories('name')
    },
    partialCostingChecked () {
      return this.enablePartialCosting && !!this.value[this.costingIsPartialAttribute]
    },
    jobLabelEnabled () {
      return this.partialCostingChecked && !this.job
    },
    jobPhaseEnabled () {
      if (!this.enhancedCostingEnabled) return
      return this.job ? !!this.job.jobPhaseMode : this.partialCostingChecked
    },
    costCodeEnabled () {
      if (!this.enhancedCostingEnabled) return
      return this.job ? !!this.job.costCodeMode : this.partialCostingChecked
    },
    uniqueIdentifier () {
      // TODO: Do job phase and cost code fields need a different identifier that includes job id?
      return `${this.value.id}-${this.value.employee}-${forceArray(this.deviceOrgUnit || []).join('-')}`
    },
    customFieldValueParams () {
      return (this.customFieldValues || [])
        .filter(value => ['bool', 'choice'].includes(value.type))
        .flatMap(value => forceArray(value.value).map(v => `${value.field}:${v}`))
    }
  },
  watch: {
    'value.jobCategory': function (value) {
      if (!value && this.job) return // probably a job without a category was just selected
      if (!this.jobCategoryEnabled) return
      this.$refs.job?.trySearch?.()
      if (this.jobPhaseEnabled) this.$refs.jobPhase?.trySearch?.()
    },
    job (job) {
      // TODO: If job changes, it needs to trigger fetchJobPhases and fetchCostCodes due to permissions effects.
      this.$emit('jobChanged', job)
      if (!this.enhancedCostingEnabled) return
      if (job) {
        if (this.jobCategoryEnabled) {
          this.value[this.jobCategoryAttribute] = job.jobCategory
        }
        // TODO: If jobPhaseMode off, shouldn't we also remove all job phase options?
        // TODO: And if jobPhaseMode off, we might need to clear job phases anyway
        // TODO: because those are the job phases for the previous job.
        // TODO: And when parent id changes we may need to also clear everything,
        // TODO: because org unit / department may have changed.
        // TODO: Similarly with cost codes.
        if (!job.jobPhaseMode) this.value[this.jobPhaseAttribute] = null
        if (!job.costCodeMode) this.value[this.costCodeAttribute] = null
        // Use condition to avoid setting job label null and making form unnecessarily dirty.
        if (this.jobLabelEnabled && this.value[this.jobLabelAttribute]) {
          this.value[this.jobLabelAttribute] = null
        }
      } else {
        this.value[this.jobPhaseAttribute] = null
        this.value[this.costCodeAttribute] = null
      }
    },
    department () {
      // refetch jobs since filter changed
      this.$refs.job?.trySearch?.()
    },
    deviceOrgUnit () {
      // refetch jobs since filter changed
      this.$refs.job?.trySearch?.()
    },
    partialCostingChecked (value) {
      if (!this.jobPhaseEnabled) {
        this.value[this.jobPhaseAttribute] = null
      }
      if (!this.costCodeEnabled) {
        this.value[this.costCodeAttribute] = null
      }
    },
    jobLabelEnabled (value) {
      if (!value) {
        this.value[this.jobLabelAttribute] = null
      }
    }
  },
  methods: {
    fetchJobs (searchText, value, limit) {
      return JobService
        .list({
          searchText,
          active: value ? 'all' : 'active',
          id: value,
          limit,
          requirePerms: !this.disableRequirePerms,
          worker: this.worker,
          deviceOrgUnit: this.deviceOrgUnit,
          orgUnit: this.orgUnit,
          department: this.department,
          userLabel: this.userLabels,
          customFieldValues: this.customFieldValueParams,
          jobCategory: this.value[this.jobCategoryAttribute]
        })
        .then(data => _.get(data, 'results', []))
    },
    fetchJobPhases (searchText, value, limit) {
      return JobPhaseService
        .list({
          searchText,
          active: value ? 'all' : 'active',
          id: value,
          limit,
          requirePerms: !this.disableRequirePerms,
          worker: this.worker,
          deviceOrgUnit: this.deviceOrgUnit,
          orgUnit: this.orgUnit,
          department: this.department,
          userLabel: this.userLabels,
          customFieldValues: this.customFieldValueParams,
          job: this.value[this.jobAttribute],
          workLabel: this.job?.labels,
          jobCategory: this.value[this.jobCategoryAttribute]
        })
        .then(data => _.get(data, 'results', []))
    },
    fetchCostCodes (searchText, value, limit) {
      const job = this.value[this.jobAttribute]
      return CostCodeService
        .list({
          searchText,
          active: value ? 'all' : 'active',
          id: value,
          limit,
          worker: this.worker,
          deviceOrgUnit: this.deviceOrgUnit,
          orgUnit: this.orgUnit,
          department: this.department,
          userLabel: this.userLabels,
          customFieldValues: this.customFieldValueParams,
          // Don't require perms if partial costing without a job.
          // TODO: Also emporary disable requirePerms and work filtering for one particular org until we resolve query branch explosion.
          // TODO: Probably need to wait until we upgrade our backend to using native IN queries.
          ...(this.enablePartialCosting && !job || this.organizationId === 5544977541234688 ? {} : {
            job,
            jobPhase: this.value[this.jobPhaseAttribute],
            requirePerms: !this.disableRequirePerms,
            workLabel: (this.job?.labels || []).concat(this.jobPhase?.labels || [])
          })
        })
        .then(data => _.get(data, 'results', []))
    }
  },
  mounted () {
    if (this.jobCategoryEnabled) {
      this.$store.dispatch('jobCategories/load')
    }
  },
  validations () {
    return {
      // TODO: Try to do some validation when costing is partial.
      value: {
        [this.jobLabelAttribute]: {
          // It doesn't seem we need any validation for label.
        },
        [this.jobCategoryAttribute]: {
          isValid: helpers.withMessage(this.invalidMessage, value => {
            if (!this.enhancedCostingEnabled) return true
            if (this.enablePartialCosting && this.value[this.costingIsPartialAttribute]) return true
            if (!this.job) return true
            if (!this.job.jobCategory) return true
            if (this.job.jobCategory === value) return true
            return false
          })
        },
        [this.jobAttribute]: {
          isValid: helpers.withMessage(this.invalidMessage, value => {
            if (this.enablePartialCosting && this.value[this.costingIsPartialAttribute]) return true
            if (value) return true
            if (this.enableJobRequiredValidation && this.jobRequired) return false
            if (!this.enhancedCostingEnabled) return true
            if (!this.jobCategoryEnabled || !this.value[this.jobCategoryAttribute]) return true
            return false
          })
        },
        [this.jobPhaseAttribute]: {
          isValid: helpers.withMessage(this.invalidMessage, value => {
            if (!this.enhancedCostingEnabled) return true
            if (this.enablePartialCosting && this.value[this.costingIsPartialAttribute]) return true
            if (!this.job) return !value
            if (!value && this.job.jobPhaseMode === 'require') return false
            if (!this.job.jobPhaseMode) return !value
            if (!this.jobPhase) return true // I think this case is transient during loading
            if (value && this.jobPhase.job && this.jobPhase.job !== this.value[this.jobAttribute]) return false
            if (value && this.jobPhase.jobCategory && this.jobPhase.jobCategory !== this.value[this.jobCategoryAttribute]) return false
            return true
          })
        },
        [this.costCodeAttribute]: {
          isValid: helpers.withMessage(this.invalidMessage, value => {
            if (!this.enhancedCostingEnabled) return true
            if (this.enablePartialCosting && this.value[this.costingIsPartialAttribute]) return true
            if (!this.job) return !value
            if (!value && this.job.costCodeMode === 'require') return false
            if (!this.job.costCodeMode) return !value
            if (!this.costCode) return true // I think this case is transient during loading
            if (value && this.costCode.job && this.costCode.job !== this.value[this.jobAttribute]) return false
            return true
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.costing-selection {
  .form-group {
    margin: .5rem;
  }
  .multiselect {
    max-width: 20rem;
  }
}
</style>
